import Vue from 'vue'
import VueRouter from 'vue-router'
import $store from '@/store/index';

Vue.use(VueRouter)

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 在此处配置路由
const routes = [{
		path: '/',
		redirect: '/Home'
	},
	{
		path: '/',
		component: () => import('../views/Base.vue'),
		children: [{
				path: '/Home',
				component: () => import('../views/Home/Home.vue'),
				meta: {
					title:  $store.state.language == 'es-ES' ? 'Inicio' : $store.state.language == 'en-GB' ? 'Home' : '首页'
				}
			},
			{
				path: '/ConnectUs',
				component: () => import('../views/ConnectUs/ConnectUs.vue'),
				meta: {
					title: '联系我们'
				}
			},
			{
				path: '/Article',
				component: () => import('../views/Article/detail.vue'),
				meta: {
					title: '文章'
				}
			},
			{
				path: '/GoodsDetail',
				component: () => import('../views/Goods/detail.vue'),
				meta: {
					title:  $store.state.language == 'es-ES' ? 'Detalles de producto' : $store.state.language == 'en-GB' ? 'Goods details' : '商品详情'
				}
			},
			{
				path: '/Detail_Mobile',
				component: () => import('../views/Goods/detail_mob.vue'),
				meta: {
					title: $store.state.language == 'es-ES' ? 'Detalles de producto' : $store.state.language == 'en-GB' ? 'Goods details' : '商品详情'
				}
			},
			{
				path: '/GoodsList',
				component: () => import('../views/Goods/list.vue'),
				meta: {
					title: !$store.state.language || $store.state.language == 'es-ES' ? 'Lista de productos' : $store.state.language == 'en-GB' ? 'product list' : '商品列表'
				}
			},
			{
				path: '/Cart',
				component: () => import('../views/Basket/basket.vue'),
				meta: {
					title: '购物车'
				}
			},
			{
				path: '/Collect',
				component: () => import('../views/Like/list.vue'),
				meta: {
					title: '收藏'
				}
			},
			{
				path: '/Search',
				component: () => import('../views/Goods/search.vue'),
				meta: {
					title: '搜索'
				}
			},
			{
				path: '/Ensure',
				component: () => import('../views/Order/ensure.vue'),
				meta: {
					title: '确认订单'
				}
			},
			{
				path: '/Mine',
				component: () => import('../views/Mine/Base.vue'),
				children: [{
						path: '/OrderList',
						component: () => import('../views/Order/list.vue'),
						meta: {
							title: '订单列表'
						}
					},
					{
						name: 'OrderDetail',
						path: '/OrderDetail',
						component: () => import('../views/Order/detail.vue'),
						meta: {
							title: '订单相亲'
						}
					},
					{
						path: '/Information',
						component: () => import('../views/Mine/information.vue'),
						meta: {
							title: '用户信息'
						}
					},
					{
						path: '/Address',
						component: () => import('../views/Address/list.vue'),
						meta: {
							title: '地址管理'
						}
					},
				]
			}
		]
	}
]

const router = new VueRouter({
	routes
})

export default router